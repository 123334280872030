const webTasks = {
  label: 'Web Tasks',
  slug: 'web',
  taskIds: process.env.REACT_APP_WEB_TASKS.split(',') || [],
};

const strategyTasks = {
  label: 'Strategy Tasks',
  slug: 'strategy',
  taskIds: process.env.REACT_APP_STRATEGY_TASKS.split(',') || [],
};

const brandTasks = {
  label: 'Brand Tasks',
  slug: 'brand',
  taskIds: process.env.REACT_APP_BRAND_TASKS.split(',') || [],
};

const contentTasks = {
  label: 'Content Tasks',
  slug: 'content',
  taskIds: process.env.REACT_APP_CONTENT_TASKS.split(',') || [],
};

const uxTasks = {
  label: 'UX Tasks',
  slug: 'ux',
  taskIds: process.env.REACT_APP_UX_TASKS.split(',') || [],
};

const uiTasks = {
  label: 'UI Tasks',
  slug: 'ui',
  taskIds: process.env.REACT_APP_UI_TASKS.split(',') || [],
};

export const tasks = [webTasks, strategyTasks, brandTasks, contentTasks, uxTasks, uiTasks];
