import { useEffect, useState } from 'react';

function getApiBaseUrl() {
  return process.env.REACT_APP_EXPRESS_SERVER;
}

export const useProjectList = () => {
  const [state, setState] = useState();
  const endpoint = `${getApiBaseUrl()}/database/query`;

  useEffect(() => {
    const dataFetch = async () => {
      const data = await (
        await fetch(endpoint, {
          method: 'POST',
        })
      ).json();

      setState(data);
    };

    dataFetch();
  }, [endpoint]);

  return state;
};

const isJson = (string) => {
  try {
    JSON.parse(string);
  } catch (e) {
    return false;
  }

  return true;
};

const groupData = (formData) => {
  const groupedFormData = [];
  Object.entries(formData).map(([key, data]) => {
    if (isJson(data)) {
      groupedFormData[key] = JSON.parse(data);
    } else {
      const splitkey = key.split('-');
      const id = splitkey.slice(0, -1).join('-');
      const properties = groupedFormData[id];

      if (properties.id === id) {
        properties[splitkey.slice(-1)] = data;
      }

      groupedFormData[id] = properties;
    }

    return groupedFormData;
  });

  return groupedFormData;
};

export const createPage = async (formData) => {
  const data = groupData(formData);
  const endpoint = `${getApiBaseUrl()}/database/create`;

  const results = Object.values(data).map(async (item) => {
    const dataProperties = item.properties;
    const primaryName = item.ClientName ?? dataProperties.Name.title[0].plain_text;
    const name = item.SecondaryName ? `${primaryName} - ${item.SecondaryName}` : primaryName;

    const updatedName = {
      ...dataProperties.Name,
      title: [
        {
          plain_text: name,
          text: {
            content: name,
          },
          type: 'text',
        },
      ],
    };

    let body = {
      parent: {
        type: 'database_id',
        database_id: process.env.REACT_APP_NOTION_CLIENT_DATABASE_ID,
      },
      properties: {
        'Project Name [Harvest Code]': updatedName,
        Budget: dataProperties.Budget,
        'Kickoff Date': dataProperties['Kickoff Date'],
      },
    };

    if (dataProperties.Currency.select) {
      body.properties.Currency = {
        select: {
          name: dataProperties.Currency.select ? dataProperties.Currency.select.name : '',
        },
      };
    }

    const fetchData = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    return fetchData.json();
  });

  return Promise.all(results);
};
