import { useData } from '../../services/harvest';
import React from 'react';

const Options = () => {
  const { data } = useData('projects');

  if (data === undefined) {
    return false;
  }

  const sorted = data.projects.sort((a, b) => (a.name > b.name ? 1 : -1));

  return sorted.map((project) => (
    <option value={project.id} key={project.id}>
      {project.name}
    </option>
  ));
};

export default function Projects() {
  return (
    <fieldset>
      <label className='field-label'>Select a Project</label>
      <div className='mb-4 text-sm'>
        If the project you are looking for is not in the list, please talk to the Finance Department.
      </div>
      <select className='rounded border p-2 w-full' id='project' name='project' required>
        <Options />
      </select>
    </fieldset>
  );
}
