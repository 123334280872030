import React, { useState } from 'react';
import { useProjectList } from '../../services/notion';
import { v4 as uuidv4 } from 'uuid';

export default function Projects() {
  const database = useProjectList();
  const [projectList, setProjectList] = useState([]);

  const handleFieldCreation = (event) => {
    const isChecked = event.currentTarget.checked;
    const project = JSON.parse(event.currentTarget.value);

    if (isChecked) {
      setProjectList([...projectList, project]);
    } else {
      const newList = projectList.filter(
        (item) => item.properties.Name.title[0].plain_text !== project.properties.Name.title[0].plain_text
      );
      setProjectList(newList);
    }
  };

  if (database === undefined) {
    return false;
  }

  if (database.message === 'error') {
    const errorBody = JSON.parse(database.error.body);

    return (
      <div>
        <p className='font-bold mb-2'>There was an error fetching the data from Notion:</p>
        <p>
          <strong>{errorBody.status}:</strong> {errorBody.message}
        </p>
      </div>
    );
  }

  const ProjectFields = () => {
    return (
      <div>
        <p className='text-sm'>
          The fields here will be setup in this format within Notion: <code>Client Name - Secondary Name</code>
        </p>

        <div className='space-y-6'>
          {projectList.map((project) => (
            <div className='grid grid-cols-2 gap-4' key={uuidv4()}>
              <div>
                <label className='block text-xs mb-2'>Client Name</label>
                <input
                  type='text'
                  className='border rounded px-3 py-2 w-full'
                  name={`${project.id}-ClientName`}
                  defaultValue={project.properties.Name.title[0].plain_text}
                />
              </div>

              <div>
                <label className='block text-xs mb-2'>Secondary Name</label>
                <input
                  type='text'
                  name={`${project.id}-SecondaryName`}
                  className='border rounded px-3 py-2 w-full'
                  placeholder='Secondary Name'
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      <h3>Select the client cards to create</h3>
      <ul className='list-none p-0 my-0 grid grid-cols-2 max-h-96 border p-3 overflow-auto rounded mb-10'>
        {database.data.results.map((item) => {
          return (
            <li key={item.id} className='m-0 flex items-start'>
              <input
                type='checkbox'
                className='mr-2 translate-y-2'
                name={item.id}
                id={item.id}
                value={JSON.stringify(item)}
                onChange={handleFieldCreation}
              />
              <label htmlFor={item.id}>{item.properties.Name.title[0].plain_text}</label>
            </li>
          );
        })}
      </ul>

      <h3>Setup the client card name</h3>
      <ProjectFields />
    </div>
  );
}
