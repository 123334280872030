import Form from './components/HarvestForm';
import Notion from './components/NotionForm';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

function App() {
  // if (!window.location.search.includes('?hash')) {
  //   const params = new URLSearchParams(window.top.location.search);

  //   if (params.get('hash') !== process.env.CI_PROJECT_ID) {
  //     return (
  //       <div className='h-screen w-full flex items-center justify-center flex-col'>
  //         <h1 className='text-5xl md:text-9xl mb-4 font-bold'>404</h1>
  //         <p className='text-2xl md:text-4xl mb-4 font-bold'>Page not found</p>
  //       </div>
  //     );
  //   }
  // }

  return (
    <div className='App my-20'>
      <div className='container prose'>
        <Accordion allowZeroExpanded='true'>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <h2>&raquo; Create a Notion Client Card</h2>
              </AccordionItemButton>
            </AccordionItemHeading>

            <AccordionItemPanel>
              <Notion />
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <h2>&raquo; Assign Tasks to a Harvest Project</h2>
              </AccordionItemButton>
            </AccordionItemHeading>

            <AccordionItemPanel>
              <Form />
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
}

export default App;
