import React, { useState, useRef } from 'react';
import Projects from './Notion/Projects';
import { createPage } from '../services/notion';

export default function Notion() {
  const [status, setStatus] = useState(false);
  const [cards, setCards] = useState([]);
  const formEl = useRef(null);

  const SuccessMessage = () => {
    if (status === 'success') {
      return (
        <div className='mt-10 border border-green-900 bg-green-200 rounded p-4'>
          <p className='my-0'>Client Cards creation successful.</p>
          <ul className='my-0'>
            {cards.map((card) => (
              <li key={`card-${card.data.id}`} className='my-0'>
                <a href={card.url}>{card.data.properties['Project Name [Harvest Code]'].title[0].plain_text}</a>
              </li>
            ))}
          </ul>
        </div>
      );
    }
  };

  const handleClientCardCreation = (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    const formJson = Object.fromEntries(formData.entries());
    const successCards = setupSuccessMessage(formJson);

    successCards
      .then((data) => {
        setCards(data);
      })
      .then(() => {
        formEl.current.classList.add('hidden');
        setStatus('success');
      });
  };

  const setupSuccessMessage = async (formJson) => {
    return await createPage(formJson);
  };

  return (
    <>
      <form onSubmit={handleClientCardCreation} ref={formEl}>
        <Projects />
        <button
          type='submit'
          className='mt-4 border border-black px-4 py-2 rounded hover:bg-black hover:text-white focus:bg-black focus:text-white transition'
        >
          Generate Client Cards
        </button>
      </form>
      <SuccessMessage />
    </>
  );
}
