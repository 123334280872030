import { useEffect, useState } from 'react';

const headers = new Headers();
headers.append('Harvest-Account-Id', process.env.REACT_APP_HARVEST_ACCOUNT_ID);
headers.append('Authorization', `Bearer ${process.env.REACT_APP_HARVEST_TOKEN}`);

export const requestOptions = {
  method: 'GET',
  headers: headers,
};

export const useData = (endpoint, args) => {
  args = {
    ...args,
    is_active: true,
  };

  const [state, setState] = useState();
  const apiEndpoint = `${process.env.REACT_APP_HARVEST_BASE}/${endpoint}?${new URLSearchParams(args).toString()}`;

  useEffect(() => {
    const dataFetch = async () => {
      const data = await (await fetch(apiEndpoint, requestOptions)).json();
      setState(data);
    };

    dataFetch();
  }, [apiEndpoint]);

  return { data: state };
};

export const getProject = async (projectId) => {
  const apiEndpoint = `${process.env.REACT_APP_HARVEST_BASE}/projects/${projectId}?is_active=true`;

  try {
    return await fetch(apiEndpoint, requestOptions).then((res) => res.json());
  } catch (error) {
    return error;
  }
};

export const getTaskById = async (taskId) => {
  const apiEndpoint = `${process.env.REACT_APP_HARVEST_BASE}/tasks/${taskId}`;

  try {
    return await fetch(apiEndpoint, requestOptions).then((res) => res.json());
  } catch (error) {
    return error;
  }
};

export const getTaskByName = async (name) => {
  const apiEndpoint = `${process.env.REACT_APP_HARVEST_BASE}/tasks`;

  try {
    const allTasks = [];
    return await fetch(apiEndpoint, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        const tasks = data.tasks;
        tasks.map((task) => {
          if (task.name === name) allTasks.push(task);
          return allTasks;
        });
      })
      .then(() => {
        return allTasks[0];
      });
  } catch (error) {
    return error;
  }
};

export const submitData = async (formData) => {
  formData = formatData(formData);

  const project = await getProject(formData.project);
  const dataToReturn = { project: project, tasks: await handleTasks(formData) };

  return dataToReturn;
};

const handleTasks = async (formData) => {
  const addedTasks = formData.tasks.map(async (taskName) => {
    const taskExists = await getTaskByName(taskName);

    if (taskExists) {
      await assignTaskToProject(formData.project, taskExists.id);
      return taskExists;
    }
  });

  return Promise.all(addedTasks);
};

const formatData = (formJson) => {
  const tasks = Object.entries(formJson)
    .filter((data) => {
      return data[0].includes('task-');
    })
    .map((data) => {
      return data[1].split(',');
    });

  return {
    project: formJson.project,
    tasks: tasks.flat(1),
  };
};

const assignTaskToProject = async (project, taskId) => {
  const apiEndpoint = `${process.env.REACT_APP_HARVEST_BASE}/projects/${project}/task_assignments`;

  try {
    return await fetch(`${apiEndpoint}?task_id=${taskId}`, {
      ...requestOptions,
      method: 'POST',
    })
      .then((res) => res.json())
      .then((data) => {
        return {
          task: data.task.name,
          status: 200,
        };
      });
  } catch (error) {
    return error;
  }
};
