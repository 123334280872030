import React, { useRef, useState } from 'react';
import Projects from './Harvest/Projects';
import Tasks from './Harvest/Tasks';
import { submitData } from '../services/harvest';

export default function Form() {
  const [status, setStatus] = useState(false);
  const [submittedData, setSubmittedData] = useState([]);

  const formEl = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());

    if (Object.keys(formJson).length <= 1) {
      setStatus('error');
      return;
    }

    await submitData(formJson).then((data) => {
      setSubmittedData(data);
      setStatus('success');

      formEl.current.classList.add('hidden');

      return data;
    });
  };

  const handleReload = () => {
    window.location.reload();
  };

  const SuccessMessage = () => {
    if (status === 'success') {
      return (
        <>
          <div className='bg-green-200 text-green-800 border border-green-900 marker:text-current rounded p-3'>
            <h2 className='text-green-800'>Tasks have been created under {submittedData.project.name}:</h2>
            <ul>
              {submittedData.tasks.map((task) => (
                <li className='my-0' key={task.id}>
                  {task.name}
                </li>
              ))}
            </ul>
          </div>

          <button
            type='button'
            onClick={handleReload}
            className='mt-6 rounded bg-black text-white p-3 hover:bg-black/75 transition'
          >
            Setup another Harvest project
          </button>
        </>
      );
    }
  };

  const ErrorMessage = () => {
    if (status === 'error') {
      return (
        <div className='bg-red-200 text-red-800 border border-red-900 rounded p-3'>
          <h2 className='text-red-800'>Error</h2>
          <p>Please select a task bucket.</p>
        </div>
      );
    }
  };

  return (
    <>
      <div className={`mb-6 status-message status-${status} ${status ? 'block' : 'hidden'}`}>
        <SuccessMessage />
        <ErrorMessage />
      </div>

      <form className='grid grid-cols-1 gap-4 transition-all' onSubmit={handleSubmit} id='form' ref={formEl}>
        <Projects />
        <Tasks />

        <button type='submit' className='rounded bg-black text-white p-3 hover:bg-black/75 transition'>
          Setup Harvest!
        </button>
      </form>
    </>
  );
}
