import React from 'react';
import { tasks } from '../../data/tasks';

const TaskGroup = () => {
  const sorted = tasks.sort((a, b) => (a.label > b.label ? 1 : -1));

  return sorted.map((group) => (
    <label className='block' htmlFor={`task-${group.slug}`} key={group.slug}>
      <input
        type='checkbox'
        id={`task-${group.slug}`}
        name={`task-${group.slug}`}
        value={group.taskIds}
        className='mr-2'
      />
      {group.label}
    </label>
  ));
};

export default function Tasks() {
  return (
    <div>
      <label className='field-label'>Tasks</label>
      <div className={`grid grid-cols-2 md:grid-flow-col md:grid-rows-3`}>
        <TaskGroup />
      </div>
    </div>
  );
}
